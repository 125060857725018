/* You can add global styles to this file, and also import other style files */
body {
    background-color: rgb(237, 240 ,246);
    font: 300 0.875em/1.2 'Ubuntu';
    color: #000;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

button { border: none; cursor: pointer; }

.advSubmitButton {
    padding: 5px 30px 5px 30px; display: inline-block; height: 40px; background-color: #b9d135; color: #fff; font-weight: bold;
}

.advSubmitButton:disabled {
    background-color: #ccc; color: #666;
}


.spinner { margin: 40px auto 0; width: 70px; text-align: center; }
.spinner > div { width: 18px; height: 18px; background-color: rgba(177,204,55,1); border-radius: 100%; display: inline-block; -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both; animation: sk-bouncedelay 1.4s infinite ease-in-out both; }
.spinner .bounce1 { -webkit-animation-delay: -0.32s; animation-delay: -0.32s; }
.spinner .bounce2 { -webkit-animation-delay: -0.16s; animation-delay: -0.16s; }
@-webkit-keyframes sk-bouncedelay { 0%, 80%, 100% { -webkit-transform: scale(0) } 40% { -webkit-transform: scale(1.0) } }
@keyframes sk-bouncedelay {
0%, 80%, 100% {  -webkit-transform: scale(0); transform: scale(0); } 40% { -webkit-transform: scale(1.0); transform: scale(1.0); } } 